import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { useDocument } from "hooks/useDocument";

const initialState = {
  data: null,
  isPending: false,
  error: null,
  success: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: null,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: null,
        success: null,
        error: null,
      };
    case "INITIAL":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "UPDATED_FORMNUDGES":
      return {
        isPending: false,
        data: action.payload,
        success: `Updated form nudges successfully.`,
        error: null,
      };
    case "CREATED_NEW_FORMNUDGE":
      return {
        isPending: false,
        data: action.payload,
        success: `Created a new form nudge.`,
        error: null,
      };
    case "DELETED_FORMNUDGE":
      return {
        isPending: false,
        data: action.payload,
        success: `Deleted a form nudge from the record.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: null,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};
const collectionPath36mTimePoint = "month36_timepoint_v01";
const query = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
  orderByQueries: [
    {
      field: "cradle2Id",
      direction: "desc",
    },
  ],
};

export const useCalculatorManager = () => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { retrieveDocs } = useDocument();
  const { pathname } = useLocation();
  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  /* const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  ); */

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      if (!pathname.includes("/forms/calculator36months")) {
        throw operationInvalidError;
      }

      const retrieved36mTimePoint = await retrieveDocs(
        collectionPath36mTimePoint,
        query
      );

      dispatchIfNotUnmounted({
        type: "INITIAL",
        payload: retrieved36mTimePoint,
      });
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchIfNotUnmounted, pathname, dispatchError, retrieveDocs]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "36 months checkpoint";
  return {
    modeTitle,
    response,
  };
};
