import { useEffect, useState, useCallback } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ErrorBoundary from "components/molecules/ErrorBoundary";

import Configurator from "components/molecules/Configurator";
import Sidenav from "components/organisms/Sidenav";

import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import brandWhite from "assets/images/logo.png";
import brandDark from "assets/images/logo.png";

import { useMuiContext, setMiniSidenav } from "context/MuiContext";

import { useAuthContext } from "context/AuthContext";
import RequireAuth from "components/molecules/RequireAuth";

import { AbacProvider } from "react-abac";

import navigate from "models/navigate";

import { useCollectionSnapshot } from "hooks/useCollectionSnapshot";
import { useDocument } from "hooks/useDocument";

const abacRulesQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
};

export default function App() {
  const [abacRules, setAbacRules] = useState([]);
  const { collectionData: abacRulesData } = useCollectionSnapshot(
    "roles",
    abacRulesQueries
  );
  const { retrieveDocs } = useDocument();

  const {
    miniSidenav,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    dispatch,
  } = useMuiContext();

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const { user, roles, displayName, displayNameKey, authIsReady } =
    useAuthContext();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const loadAbacRules = useCallback(async () => {
    const rulesRetrievedOnce = await retrieveDocs("roles", abacRulesQueries);
    const data = abacRulesData.length > 0 ? abacRulesData : rulesRetrievedOnce;
    const ruleEntries = new Map(
      data.map((rule) => {
        const entries = new Map(
          rule.data.permissions.map((perm) => [perm, true])
        );
        const permissionsObj = Object.fromEntries(entries);
        return [rule.data.roleName, permissionsObj];
      })
    );
    const rules = Object.fromEntries(ruleEntries);
    setAbacRules(rules);
  }, [abacRulesData, retrieveDocs]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    loadAbacRules();
  }, [loadAbacRules]);

  const getRoutes = (allRoutes) =>
    allRoutes
      .flatMap((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return (
            <Route
              key={route.key}
              path={route.route}
              element={
                route.auth ? (
                  <RequireAuth>{route.component}</RequireAuth>
                ) : (
                  route.component
                )
              }
            />
          );
        }

        return null;
      })
      .filter((route) => {
        return route != null;
      });

  return (
    <>
      {authIsReady && (
        <ErrorBoundary>
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {/* {abacRules?.length > 0 ? ( */}
            <AbacProvider
              rules={abacRules}
              user={user}
              roles={roles}
              permissions={[]}
            >
              {user && user.emailVerified && layout === "dashboard" && (
                <>
                  <Sidenav
                    color={sidenavColor}
                    brand={
                      (transparentSidenav && !darkMode) || whiteSidenav
                        ? brandDark
                        : brandWhite
                    }
                    brandName="CRADLE 2"
                    links={navigate(displayName, displayNameKey)}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />
                  <Configurator />
                </>
              )}
              {user && user.emailVerified && layout === "vr" && (
                <Configurator />
              )}

              <Routes>
                {getRoutes(navigate(displayName, displayNameKey))}
              </Routes>
            </AbacProvider>
            {/* ) : (
              <SubmissionProgress />
            )} */}
          </ThemeProvider>
        </ErrorBoundary>
      )}
    </>
  );
}
