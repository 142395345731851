// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

// @mui icons
//import FacebookIcon from "@mui/icons-material/Facebook";
//import TwitterIcon from "@mui/icons-material/Twitter";
//import InstagramIcon from "@mui/icons-material/Instagram";
//import PinterestIcon from "@mui/icons-material/Pinterest";
//import LinkedInIcon from "@mui/icons-material/LinkedIn";

import { useCacheContext } from "context/CacheContext";

function DefaultFooter({ company, links, color }) {
  let { currentVersion } = useCacheContext();
  const { href, name } = company;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="body2" fontWeight="regular" color={color}>
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} lg={8}>
        <MDBox
          component="ul"
          sx={({ breakpoints }) => ({
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            listStyle: "none",
            mt: 3,
            mb: 0,
            p: 0,

            [breakpoints.up("lg")]: {
              mt: 0,
            },
          })}
        >
          {renderLinks()}
        </MDBox>
      </Grid>
      {/*<Grid item xs={12} lg={8}>
        <MDBox display="flex" justifyContent="center" mt={1} mb={3}>
          <MDBox mr={3} color={color}>
            <FacebookIcon fontSize="small" />
          </MDBox>
          <MDBox mr={3} color={color}>
            <TwitterIcon fontSize="small" />
          </MDBox>
          <MDBox mr={3} color={color}>
            <InstagramIcon fontSize="small" />
          </MDBox>
          <MDBox mr={3} color={color}>
            <PinterestIcon fontSize="small" />
          </MDBox>
          <MDBox color={color}>
            <LinkedInIcon fontSize="small" />
          </MDBox>
        </MDBox>
      </Grid>*/}
      <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
        <Link href={href} target="_blank">
          <MDTypography variant="body2" fontWeight="regular" color={color}>
            Copyright &copy; {new Date().getFullYear()} by {name}.
          </MDTypography>
        </Link>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
        <MDTypography variant="body3" fontWeight="regular" color={color}>
          v{currentVersion}
        </MDTypography>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of DefaultFooter
DefaultFooter.defaultProps = {
  company: { href: "http://www.vixel.com.sg/", name: "ViXEL" },
  links: [
    { href: "", name: "About The Project" },
    {
      href: "",
      name: "CRADLE 2 Team",
    },
  ],
  light: false,
};

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
  color: PropTypes.oneOf(["text", "secondary", "white", "dark"]),
};

export default DefaultFooter;
