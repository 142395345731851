import * as Yup from "yup";
import form from "pages/nudges/groupB/schemas/form";

const {
  formField: { id, type, month, contents },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [id.name]: Yup.string().required(id.errorMsg).max(200, id.invalidMsg),
  [type.name]: Yup.string().required(type.errorMsg).max(200, type.invalidMsg),
  [month.name]: Yup.string().required(month.errorMsg).max(200, type.invalidMsg),
  [contents.name]: Yup.string()
    .required(contents.errorMsg)
    .max(5000, contents.invalidMsg),
});

export { validations as default, noValidation };
