import { useState } from "react";
import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import SubmissionProgress from "components/organisms/SubmissionProgress";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import { Formik, Form } from "formik";
import form from "pages/nudges/groupA/schemas/form";
import NudgeDetails from "pages/nudges/groupA/manage/components/NudgeDetails";

import Dialog from "@mui/material/Dialog";
//import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { AllowedTo } from "react-abac";
import { useFormNudgeManager } from "pages/forms/hooks/useFormNudgeManager";
import initialValues from "pages/nudges/groupA/schemas/initialValues";
import { List } from "rsuite";

function NudgesGroupA() {
  const [nudgeValues, setNudgeValues] = useState(initialValues);
  const [modeSubmission, setModeSubmission] = useState("");
  const { formId, formField } = form;

  const {
    modeTitle,
    modePermission,
    modeValidation,
    modeFieldDisabled,
    response,
    //dispatchDismiss,
    dispatchError,
    formnudges,
    setFormNudges,
    submitEdit,
    submitCreate,
    submitDelete,
    submitUpdate,
    formnudgeIndex,
    updateMode,
  } = useFormNudgeManager();

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newFormNudges = [...formnudges];
      const moveData = newFormNudges.splice(oldIndex, 1);
      newFormNudges.splice(newIndex, 0, moveData[0]);
      setFormNudges(newFormNudges);
      submitUpdate(newFormNudges);
    }
  };

  const handleSubmit = async (values, actions) => {
    if (updateMode.current === "edit") {
      submitEdit(values);
      setOpenDialog(false);
    } else if (updateMode.current === "insert") {
      submitCreate(values);
      setOpenDialog(false);
    }
  };

  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />}>
        {response.isPending ? (
          <SubmissionProgress />
        ) : (
          <>
            {response.error && (
              <>
                {response.error?.name === "OperationInvalidError" && (
                  <InvalidOperation />
                )}
                {response.error?.name === "OperationIncompleteError" && (
                  <IncompleteOperation />
                )}
                <ErrorSnackbar
                  title={modeTitle}
                  dateTime=""
                  message={response.error?.message}
                  autoDismiss
                />
              </>
            )}
            {response.success && (
              <SuccessSnackbar
                title={modeTitle}
                dateTime=""
                message={response.success}
                autoDismiss
              />
            )}
            <MDBox mt={3} mb={3}>
              <List bordered hover sortable onSort={handleSortEnd}>
                {!!formnudges &&
                  formnudges.map((formnudge, index) => (
                    <List.Item key={index} index={index}>
                      <Card
                        raised={true}
                        sx={{
                          p: 4,
                          pt: 2,
                          m: 2,
                          border: "1px solid red",
                          backgroundColor: "warning",
                        }}
                      >
                        <MDBox display="flex" justifyContent="right" gap={1}>
                          <MDButton
                            size="small"
                            variant="gradient"
                            color="info"
                            iconOnly
                            onClick={() => {
                              const formnudge = formnudges[index];
                              setNudgeValues({
                                id: formnudge.id,
                                month: formnudge.month,
                                type: formnudge.type,
                                contents: formnudge.contents,
                              });
                              formnudgeIndex.current = index;
                              updateMode.current = "edit";
                              setModeSubmission("UPDATE");
                              setOpenDialog(true);
                            }}
                          >
                            <ModeEditIcon />
                          </MDButton>
                          <MDButton
                            size="small"
                            variant="gradient"
                            color="info"
                            iconOnly
                            onClick={() => {
                              submitDelete(index);
                            }}
                          >
                            <RemoveCircleOutlineIcon />
                          </MDButton>
                        </MDBox>
                        <MDBox
                          borderRadius="lg"
                          sx={{
                            p: 0,
                            mt: 1,
                            mb: 1,
                            border: "1px solid grey",
                            opacity: 0.3,
                          }}
                        ></MDBox>
                        <MDBox display="flex" gap={2}>
                          <MDTypography variant="body2" fontWeight="medium">
                            {formnudge.id} - {formnudge.type}
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" gap={2} textAlign="justify">
                          <MDTypography variant="body3" fontWeight="regular">
                            {formnudge.contents}
                          </MDTypography>
                        </MDBox>
                      </Card>
                      <MDBox
                        display="flex"
                        justifyContent="right"
                        gap={1}
                        sx={{ p: 0, mr: 3, mb: 3 }}
                      >
                        <MDButton
                          size="small"
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setNudgeValues({
                              id: "",
                              month: "",
                              type: "",
                              contents: "",
                            });
                            formnudgeIndex.current = index;
                            updateMode.current = "insert";
                            setModeSubmission("CREATE");
                            setOpenDialog(true);
                          }}
                        >
                          INSERT A NEW NUDGE HERE
                        </MDButton>
                      </MDBox>
                    </List.Item>
                  ))}
              </List>
            </MDBox>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              fullWidth
              maxWidth="lg"
            >
              <DialogTitle>
                <MDButton variant="gradient" color="success" iconOnly>
                  <NotificationsActiveIcon />
                </MDButton>
                &nbsp; Insert New Nudge
              </DialogTitle>
              <DialogContent dividers>
                <MDBox mb={3}>
                  <Formik
                    enableReinitialize
                    initialValues={nudgeValues}
                    validationSchema={modeValidation}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => (
                      <Form id={formId} autoComplete="off">
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <NudgeDetails
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MDBox
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <MDBox></MDBox>
                              <MDButton
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {modeSubmission}
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </DialogContent>
            </Dialog>
          </>
        )}
      </AllowedTo>
    </DashboardLayout>
  );
}

export default NudgesGroupA;
