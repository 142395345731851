import { useState } from "react";

import NavCoverLayout from "components/templates/generic/layouts/NavCoverLayout";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Container from "@mui/material/Container";

import MDBox from "components/atoms/MDBox";

import PricingCards from "pages/main/landing/components/PricingCards";
import TrustedBrands from "pages/main/landing/components/TrustedBrands";
import Faq from "pages/main/landing/components/Faq";

import logoWideImage from "assets/images/logo-wide.png";
import bgImage from "assets/images/bg-main.png";

import mainRoutes from "models/routes";

function Landing() {
  const [tabValue, setTabValue] = useState(0);
  const [prices, setPrices] = useState(["59", "89", "99"]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "annual") {
      setPrices(["119", "159", "399"]);
    } else {
      setPrices(["59", "89", "99"]);
    }
  };

  return (
    <NavCoverLayout
      coverHeight={"40vh"}
      image={bgImage}
      mainTitle={"Pick the best plan for you"}
      subTitle={
        "You have Free Unlimited Updates and Premium Support on each package."
      }
      logo={
        <MDBox
          bgColor="white"
          px="0.4rem"
          py="0.4rem"
          borderRadius="xl"
          component="img"
          src={logoWideImage}
          alt="Brand"
          width="8rem"
        />
      }
      action={{
        type: "internal",
        route: "/authentication/signin",
        label: "Sign in",
      }}
      routes={mainRoutes}
    >
      <Grid container sx={{ px: 6, my: 8 }}>
        <Grid item xs={12}>
          <Card sx={{ mt: -16 }}>
            <MDBox minWidth={{ xs: "22rem", md: "25rem" }} mx="auto" mt={6}>
              <AppBar position="static">
                <Tabs value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    id="monthly"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Monthly
                      </MDBox>
                    }
                  />
                  <Tab
                    id="annual"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Annual
                      </MDBox>
                    }
                  />
                </Tabs>
              </AppBar>
            </MDBox>
            <Container>
              <PricingCards prices={prices} />
              <TrustedBrands />
              <Faq />
            </Container>
          </Card>
        </Grid>
      </Grid>
    </NavCoverLayout>
  );
}

export default Landing;
