import { useEffect, useCallback, useState, useReducer, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { Permission } from "models/abac";
import { useAbac } from "react-abac";

import validations from "pages/nudges/groupB/schemas/validations";
import initialValues from "pages/nudges/groupB/schemas/initialValues";
const collectionPathNudges = "nudges";
const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "UPDATED_NUDGES":
      return {
        isPending: false,
        data: action.payload,
        success: `Updated Group B nudges successfully.`,
        error: null,
      };
    case "CREATED_NEW_NUDGE":
      return {
        isPending: false,
        data: action.payload,
        success: `Created new nudge in Group B successfully.`,
        error: null,
      };
    case "DELETED_NUDGE":
      return {
        isPending: false,
        data: action.payload,
        success: `Deleted a nudge in Group B.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useNudgeManager = () => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { pathname } = useLocation();
  const { retrieveDoc, updateDoc, serverTimestamp } = useDocument();
  const [nudges, setNudges] = useState(null);
  const { userHasPermissions } = useAbac();
  const { user } = useAuthContext();
  const nudgeIndex = useRef(null);
  const updateMode = useRef(null);

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      if (!pathname.includes("/nudges/groupB")) {
        throw operationInvalidError;
      }

      const retrievedNudges = await retrieveDoc(collectionPathNudges, "GroupB");

      !!retrievedNudges.data?.nudges
        ? setNudges(retrievedNudges.data?.nudges)
        : setNudges(null);

      dispatchIfNotUnmounted({
        type: "INITIAL",
        payload: retrievedNudges.data,
      });
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchIfNotUnmounted, pathname, dispatchError, retrieveDoc, setNudges]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  const modeValidation = validations;
  const modeFieldDisabled = false;
  let modePermission = Permission.READ_ALL_NUDGES;
  let modeTitle = "Nudges for Group B";

  const submitMoveUp = async (idx) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        const newNudges = [...nudges];
        const nudgeRemoved = newNudges.splice(idx, 1);
        newNudges.splice(idx - 1, 0, nudgeRemoved[0]);
        setNudges(newNudges);

        const updatedDoc = await updateDoc(collectionPathNudges, "GroupB", {
          nudges: newNudges,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "UPDATED_NUDGES",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to update the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to update the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitMoveDown = async (idx) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        const newNudges = [...nudges];
        const nudgeRemoved = newNudges.splice(idx, 1);
        newNudges.splice(idx + 1, 0, nudgeRemoved[0]);
        setNudges(newNudges);

        const updatedDoc = await updateDoc(collectionPathNudges, "GroupB", {
          nudges: newNudges,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "UPDATED_NUDGES",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to update the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to update the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitDelete = async (idx) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.DELETE_NUDGE)) {
        const newNudges = [...nudges];
        newNudges.splice(idx, 1);
        setNudges(newNudges);

        const updatedDoc = await updateDoc(collectionPathNudges, "GroupB", {
          nudges: newNudges,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "DELETED_NUDGE",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to update the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to update the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitUpdate = async (nudges) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        const updatedDoc = await updateDoc(collectionPathNudges, "GroupB", {
          nudges: nudges,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "UPDATED_NUDGES",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to edit the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to edit the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitEdit = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        nudges[nudgeIndex.current].id = values.id;
        nudges[nudgeIndex.current].month = values.month;
        nudges[nudgeIndex.current].type = values.type;
        nudges[nudgeIndex.current].contents = values.contents;
        setNudges(nudges);

        const updatedDoc = await updateDoc(collectionPathNudges, "GroupB", {
          nudges: nudges,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "UPDATED_NUDGES",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to edit the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to edit the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitCreate = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.CREATE_NUDGE)) {
        nudges.splice(nudgeIndex.current + 1, 0, values);
        setNudges(nudges);

        const updatedDoc = await updateDoc(collectionPathNudges, "GroupB", {
          nudges: nudges,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "CREATED_NEW_NUDGE",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to edit the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to edit the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    modeTitle,
    modePermission,
    response,
    dispatchError,
    nudges,
    setNudges,
    submitMoveUp,
    submitMoveDown,
    submitEdit,
    submitCreate,
    submitDelete,
    submitUpdate,
    nudgeIndex,
    updateMode,
    modeValidation,
    modeFieldDisabled,
  };
};
