/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `auth` key is used for marking an item on the Sidenav that require authentication to access the link.
  9. The `permissions` key is used for marking an item on the Sidenav that require specified roles to access the link.
  10. The `route` key is used for the "path" parameter of <Route> component.
  11. The `link` key is used for the "to" parameter of <Link> component.
  12. The `href` key is used to store the external links location.
  13. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  14. The `component` key is used to store the component of its route.
*/

import { Navigate } from "react-router-dom";

import AccountProfile from "pages/account/manage/AccountProfile";
import NudgesGroupA from "pages/nudges/groupA/manage/NudgesGroupA";
import NudgesGroupB from "pages/nudges/groupB/manage/NudgesGroupB";
import GoogleForms from "pages/forms/manage/GoogleForms";
import NewParticipant from "pages/participants/new/NewParticipant";
import ManageParticipant from "pages/participants/manage/ManageParticipants";
import Calculator36months from "pages/calculator/36months/calculator36months";

//import ManageCompany from "pages/settings/company/manage/ManageCompany";
import NewUser from "pages/settings/user/new/NewUser";
import ManageUsers from "pages/settings/users/manage/ManageUsers";
import ManageRoles from "pages/settings/roles/manage/ManageRoles";

import Landing from "pages/main/landing";

import SignIn from "pages/authentication/signin";
import SignUp from "pages/authentication/signup";
import VerifyEmail from "pages/authentication/verifyemail";
import ResetPassword from "pages/authentication/resetpassword";

import MDAvatar from "components/atoms/MDAvatar";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewListIcon from "@mui/icons-material/ViewList";
import GroupsIcon from "@mui/icons-material/Groups";

import { Permission } from "models/abac";

const defaultSignedInPath = "/account/profile";

const navigate = (displayName, displayNameKey) => {
  return [
    {
      name: displayName ?? "Unknown",
      key: displayNameKey ?? "unknown",
      auth: true,
      permissions: Permission.VIEW_MEMBER_DASHBOARD,
      link: `/account/profile`,
      route: `/account/profile`,
      component: <AccountProfile />,
      icon: (
        <MDAvatar bgColor="info" size="sm">
          {displayName?.charAt(0).toUpperCase()}
        </MDAvatar>
      ),
    },
    {
      type: "divider",
      key: "divider-menu",
    },
    {
      type: "title",
      title: "Menu",
      key: "title-menu",
    },

    {
      type: "collapse",
      name: "Nudges",
      key: "nudges",
      auth: true,
      permissions: Permission.VIEW_SETTINGS_ACTION,
      icon: <ViewListIcon />,
      collapse: [
        {
          name: "Group A",
          key: "nudges-group-a",
          auth: true,
          permissions: [Permission.VIEW_ADMIN_DASHBOARD],
          route: "/nudges/groupA",
          link: "/nudges/groupA",
          component: <NudgesGroupA />,
        },
        {
          name: "Group B",
          key: "nudges-group-b",
          auth: true,
          permissions: [Permission.VIEW_ADMIN_DASHBOARD],
          route: "/nudges/groupB",
          link: "/nudges/groupB",
          component: <NudgesGroupB />,
        },
      ],
    },

    {
      type: "collapse",
      name: "Forms",
      key: "forms",
      auth: true,
      permissions: Permission.VIEW_SETTINGS_ACTION,
      icon: <ViewListIcon />,
      collapse: [
        {
          name: "Google Forms",
          key: "google-forms",
          auth: true,
          permissions: [Permission.VIEW_ADMIN_DASHBOARD],
          route: "/forms/googleforms",
          link: "/forms/googleforms",
          component: <GoogleForms />,
        },
      ],
    },

    {
      type: "collapse",
      name: "Calculator",
      key: "calculator",
      auth: true,
      permissions: Permission.VIEW_SETTINGS_ACTION,
      icon: <ViewListIcon />,
      collapse: [
        {
          name: "36 months",
          key: "calculator36months",
          auth: true,
          permissions: [Permission.VIEW_ADMIN_DASHBOARD],
          route: "/forms/calculator36months",
          link: "/forms/calculator36months",
          component: <Calculator36months />,
        },
      ],
    },

    {
      type: "collapse",
      name: "Participants",
      key: "participants",
      auth: true,
      permissions: Permission.VIEW_SETTINGS_ACTION,
      icon: <GroupsIcon />,
      collapse: [
        {
          name: "New Participant",
          key: "new-participant",
          auth: true,
          permissions: [Permission.VIEW_ADMIN_DASHBOARD],
          route: "/participants/new",
          link: "/participants/new",
          component: <NewParticipant />,
        },
        {
          name: "Participants",
          key: "participants",
          auth: true,
          permissions: [Permission.VIEW_ADMIN_DASHBOARD],
          route: "/participants/manage",
          link: "/participants/manage",
          component: <ManageParticipant />,
        },
      ],
    },

    {
      type: "divider",
      key: "divider-settings",
      auth: true,
      permissions: [Permission.VIEW_ADMIN_DASHBOARD],
    },
    {
      type: "title",
      title: "Admin",
      key: "title-admin",
      auth: true,
      permissions: [Permission.VIEW_ADMIN_DASHBOARD],
    },
    {
      type: "collapse",
      name: "Settings",
      key: "settings",
      auth: true,
      permissions: Permission.VIEW_SETTINGS_ACTION,
      icon: <SettingsIcon />,
      collapse: [
        {
          name: "New User",
          key: "new-user",
          auth: true,
          permissions: [Permission.VIEW_ADMIN_DASHBOARD],
          route: "/settings/user",
          link: "/settings/user",
          component: <NewUser />,
        },
        {
          name: "Users",
          key: "manage-users",
          auth: true,
          permissions: [Permission.VIEW_ADMIN_DASHBOARD],
          route: "/settings/users",
          link: "/settings/users",
          component: <ManageUsers />,
        },
        {
          name: "Roles",
          key: "manage-roles",
          auth: true,
          permissions: [Permission.VIEW_SUPERADMIN_DASHBOARD],
          route: "/settings/roles",
          link: "/settings/roles",
          component: <ManageRoles />,
        },
      ],
    },
    {
      name: "Landing",
      key: "landing",
      route: "/main/landing",
      component: <Landing />,
    },
    {
      name: "Sign In",
      key: "sign-in",
      route: "/authentication/signin",
      component: <SignIn />,
    },
    {
      name: "Sign Up",
      key: "sign-up",
      route: "/authentication/signup",
      component: <SignUp />,
    },
    {
      name: "Reset Password",
      key: "reset-password",
      route: "/authentication/resetpassword",
      component: <ResetPassword />,
    },
    {
      name: "Verify Email",
      key: "verify-email",
      auth: true,
      route: "/authentication/verifyemail",
      component: <VerifyEmail />,
    },
    {
      name: "All",
      key: "all",
      route: "*",
      /*component: <Navigate to={`/main/landing`} />,*/
      component: <Navigate to={`/account/profile`} />,
    },

    /*{
      name: "Manage QA Survey",
      key: "manage-qa-survey",
      auth: true,
      route: "/assessments/:mode",
      component: <QASurveyProxy />,
    },
    {
      name: "Manage QA Advisory",
      key: "manage-qa-advisory",
      auth: true,
      route: "/assessments/:mode",
      component: <QAAdvisoryProxy />,
    },*/
  ];
};

export { navigate as default, defaultSignedInPath };
