import PropTypes from "prop-types";

import GenericTemplate from "components/templates/GenericTemplate";

import BackgroundDesign from "../../designs/BackgroundDesign";
import ContentFragment from "../../fragments/ContentFragment";
import FooterFragment from "../../fragments/FooterFragment";

function PlainFullLayout({ image, children }) {
  return (
    <GenericTemplate>
      <BackgroundDesign image={image}>
        <ContentFragment>{children}</ContentFragment>
      </BackgroundDesign>
      <FooterFragment color={"secondary"}></FooterFragment>
    </GenericTemplate>
  );
}

// Typechecking props for the NavFullLayout
PlainFullLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PlainFullLayout;
