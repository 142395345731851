// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import typography from "assets/theme/base/typography";

import { useCacheContext } from "context/CacheContext";

function DashboardFooter({ company, links, color }) {
  let { currentVersion } = useCacheContext();
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="body3" fontWeight="regular" color={color}>
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color={color}
        fontSize={size.sm}
        px={1.5}
        pt={1.5}
      >
        <Link href={href} target="_blank">
          <MDTypography variant="body3" fontWeight="regular" color={color}>
            Copyright &copy; {new Date().getFullYear()} by {name}.
          </MDTypography>
        </Link>
      </MDBox>
      <MDBox>
        <MDTypography variant="body3" fontWeight="regular" color={color}>
          v{currentVersion}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DashboardFooter
DashboardFooter.defaultProps = {
  company: { href: "http://www.vixel.com.sg/", name: "ViXEL" },
  links: [
    { href: "", name: "About The Project" },
    {
      href: "",
      name: "CRADLE 2 Team",
    },
  ],
  light: false,
};

// Typechecking props for the DashboardFooter
DashboardFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
  color: PropTypes.oneOf(["text", "white", "dark"]),
};

export default DashboardFooter;
