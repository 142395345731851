import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";

import { useLogManager } from "hooks/useLogManager";
import { useDocument } from "hooks/useDocument";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";

import initialValues from "pages/account/manage/schemas/initialValues";
import validations from "pages/account/manage/schemas/validations";

const collectionPath = "users";

const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL_USER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "RETRIEVED_USER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "UPDATED_USER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully updated my profile.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useAccountManager = () => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const { user } = useAuthContext();

  const { userHasPermissions } = useAbac();

  const { logUserActivity } = useLogManager();
  const { retrieveDoc, updateDoc, serverTimestamp } = useDocument();

  const { pathname } = useLocation();

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        ![
          "PermissionDeniedError",
          "OperationInvalidError",
          "CustomerInvalidError",
        ].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  // TODO: Refactor to DAO Layer
  const toPersistenceValue = useCallback(
    (document) => {
      try {
        if (document.signatureAttachments) {
          document.signatureAttachments = document.signatureAttachments.map(
            (element) => {
              const { attachmentName, attachmentPath, attachmentURL } = element;
              return { attachmentName, attachmentPath, attachmentURL };
            }
          );
        }

        return document;
      } catch (err) {
        dispatchError(err);
      }
    },
    [dispatchError]
  );

  // TODO: Refactor to DAO Layer
  const toPresentationValue = useCallback(
    (data) => {
      try {
        // nothing to convert at this moment

        return data;
      } catch (err) {
        dispatchError(err);
      }
    },
    [dispatchError]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";
      const retrievedUser =
        user.uid && (await retrieveDoc(collectionPath, user.uid));

      // validate operation

      if (!pathname.includes("/account/profile")) {
        throw operationInvalidError;
      }

      dispatchIfNotUnmounted({
        type: "RETRIEVED_USER",
        payload: toPresentationValue(retrievedUser.data),
      });
    } catch (err) {
      dispatchError(err);
    }
  }, [
    dispatchIfNotUnmounted,
    user.uid,
    retrieveDoc,
    pathname,
    toPresentationValue,
    dispatchError,
  ]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "Account Profile";
  let modeSubmit = "Update";
  let modeFieldDisabled = false;
  let modePermission = Permission.VIEW_MEMBER_DASHBOARD;
  let modeValidation = validations;

  const submitEdit = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_USER)) {
        values = toPersistenceValue(values);
        const updatedDoc = await updateDoc(collectionPath, user.uid, {
          ...values,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        await logUserActivity({
          uid: user.uid,
          activity: "update account profile",
          document: updatedDoc,
          timestamp: serverTimestamp(),
        });

        dispatchIfNotUnmounted({
          type: "UPDATED_USER",
          payload: toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to update user profile."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitEdit,
    response,
    dispatchDismiss,
    dispatchError,
  };
};
