import form from "pages/nudges/groupA/schemas/form";

const {
  formField: { id, type, month, contents },
} = form;

const initialValues = {
  [id.name]: "",
  [type.name]: "",
  [month.name]: "",
  [contents.name]: "",
};

export { initialValues as default };
