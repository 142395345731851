import { useState, useRef, useCallback } from "react";
import { useDocument } from "hooks/useDocument";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import MDButton from "components/atoms/MDButton";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import { useCollectionSnapshot } from "hooks/useCollectionSnapshot";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

const parentsQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
};

function SelectParticipant({
  setParentData,
  selectedAccount,
  setSelectedAccount,
  childrenData,
  //whatsappDates,
  //whatsappDatesForms,
  countRefresh,
  setCountRefresh,
  setShowEditPanel,
  handleDelete,
}) {
  const { retrieveDoc } = useDocument();
  const childrenIds = useRef(null);
  const { collectionData: parentsData } = useCollectionSnapshot(
    "parents",
    parentsQueries
  );
  const accountOptions = parentsData.map((option) => {
    return option.data.userName;
  });

  const updateData = useCallback(
    async (childrenIds) => {
      const childrenDocs = await Promise.all(
        childrenIds.current.map(async (childId) => {
          const childDoc = await retrieveDoc("children", childId);
          return childDoc;
        })
      );
      childrenData.current = childrenDocs;
    },
    [retrieveDoc, childrenData]
  );

  const [open, setOpen] = useState(false);
  const showDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const deleteParticipant = async () => {
    await handleDelete();
    closeDialog();
    //setParentData(null);
    //childrenData.current = null;
    //setSelectedAccount(null);
    //setShowEditPanel(false);
  };

  return (
    <Card id="select-participant">
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Participant</MDTypography>
        </MDBox>

        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                Participant ID
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container rowSpacing={{ xs: 1 }} columnSpacing={{ xs: 1 }}>
                <Grid item xs={9}>
                  <Autocomplete
                    size="small"
                    value={selectedAccount}
                    onChange={async (e, account) => {
                      const parentData = parentsData.filter(
                        (parent) => parent.data.userName === account
                      );

                      childrenIds.current = parentData[0].data.childrenIds;
                      await updateData(childrenIds);
                      setSelectedAccount(account);
                      setParentData(parentData[0]);
                    }}
                    options={accountOptions}
                    disabled={false}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Participant" />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MDButton
                    size="small"
                    variant="gradient"
                    color="info"
                    iconOnly
                    onClick={async () => {
                      const parentData = parentsData.filter(
                        (parent) => parent.data.userName === selectedAccount
                      );

                      childrenIds.current = parentData[0].data.childrenIds;
                      await updateData(childrenIds);
                      setCountRefresh(countRefresh + 1);
                    }}
                  >
                    <RefreshIcon />
                  </MDButton>{" "}
                  <MDButton
                    size="small"
                    variant="gradient"
                    color="success"
                    iconOnly
                    onClick={async () => {
                      setShowEditPanel(true);
                    }}
                  >
                    <EditIcon />
                  </MDButton>{" "}
                  <MDButton
                    size="small"
                    variant="gradient"
                    color="warning"
                    iconOnly
                    onClick={async () => {
                      !!selectedAccount && showDialog();
                    }}
                  >
                    <DeleteIcon />
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
        <Dialog open={open} onClose={closeDialog}>
          <DialogTitle textAlign="center">ALERT!</DialogTitle>
          <DialogContent dividers>
            <MDBox
              borderRadius="lg"
              sx={{ p: 1, mb: 2, border: "1px solid red" }}
            >
              <MDBox>
                <MDTypography variant="body2" fontWeight="medium">
                  Delete Participant
                </MDTypography>
              </MDBox>
              <MDBox>
                You are about to delete Participant {selectedAccount}. This
                procedure is irrevisible.
              </MDBox>
              <MDBox>Do you want to proceed?</MDBox>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton
              size="small"
              variant="gradient"
              color="error"
              onClick={closeDialog}
            >
              CANCEL
            </MDButton>
            <MDButton
              size="small"
              variant="gradient"
              color="success"
              onClick={deleteParticipant}
            >
              OKAY
            </MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
    </Card>
  );
}

export default SelectParticipant;
