const form = {
  formId: "nudgegroupA-form",
  formField: {
    id: {
      name: "id",
      label: "Label",
      type: "text",
      errorMsg: "Label is required.",
      invalidMsg: "Label is invalid.",
    },
    type: {
      name: "type",
      label: "Type",
      type: "text",
      errorMsg: "Type is required.",
      invalidMsg: "Type is invalid.",
    },
    month: {
      name: "month",
      label: "Month",
      type: "text",
      errorMsg: "Month is required.",
      invalidMsg: "Month is invalid.",
    },
    contents: {
      name: "contents",
      label: "Contents",
      type: "text",
      errorMsg: "Contents is required.",
      invalidMsg: "Contents is invalid.",
    },
  },
};

export { form as default };
