import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";
import { DatePicker } from "rsuite";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import SubmissionProgress from "components/organisms/SubmissionProgress";
import { sendWhatsAppMsg } from "services/callable";
import moment from "moment";

const _ = require("lodash");
//const NEWLINE = "\r\n";

function ChildrenInformation({
  parentData,
  submitUpdateNudges,
  submitUpdateFormNudges,
  tabValue,
  setTabValue,
  toggleButtonValue,
  setToggleButtonValue,
  activeParentId,
  childrenData,
  childrenIds,
  setChildrenIds,
  whatsappDates,
  whatsappDatesForms,
  countRefresh,
}) {
  const [childData, setChildData] = useState(null);
  const [dates, setDates] = useState(null);
  const [showProcessing, setShowProcessing] = useState(false);

  useEffect(() => {
    try {
      setShowProcessing(true);
      if (!!parentData) {
        setChildData(childrenData.current[tabValue]);

        const datesArray = childrenData.current.map((doc) => {
          const nudges = doc.data.nudges;
          const dates = nudges.map((nudge) => {
            return nudge.date;
          });
          return dates;
        });
        whatsappDates.current = datesArray;

        const datesArrayForms = childrenData.current.map((doc) => {
          const formnudges = doc.data.formnudges;
          const dates = formnudges.map((formnudge) => {
            return formnudge.date;
          });
          return dates;
        });
        whatsappDatesForms.current = datesArrayForms;

        if (toggleButtonValue === "nudges") {
          setDates(whatsappDates.current[tabValue]);
        } else {
          setDates(whatsappDatesForms.current[tabValue]);
        }

        const Ids = childrenData.current.map((doc) => {
          return doc.id;
        });
        setChildrenIds(Ids);
      }
    } catch (err) {
      //dispatchMainError(err);
    } finally {
      setShowProcessing(false);
    }
  }, [
    parentData,
    childrenData,
    tabValue,
    toggleButtonValue,
    whatsappDates,
    whatsappDatesForms,
    countRefresh,
    setChildrenIds,
  ]);

  const handleTabIndexChange = (event, value) => {
    setTabValue(value);
  };

  const handleToggleButtonChange = (event, newValue) => {
    setToggleButtonValue(newValue);
    if (newValue === "nudges") {
      setDates(whatsappDates.current[tabValue]);
    } else {
      setDates(whatsappDatesForms.current[tabValue]);
    }
  };

  const getDateNowNanoseconds = () => {
    const now_millisec = Date.now();
    const seconds = Math.floor(now_millisec / 1000);
    const nanoseconds = (now_millisec - seconds * 1000) * 1000000;
    return { seconds, nanoseconds };
  };

  const sendNudgeTemplate = async (templateName) => {
    const response = await sendWhatsAppMsg({
      type: "template",
      name: templateName,
      mobile: parentData.data.mobile,
    });
    return { response };
  };

  const getResponseString = (nudge) => {
    const getResString = (nudge) => {
      if (nudge?.response) {
        const resDatesArray = nudge.response.map((elm, index) => {
          return (
            "#" +
            (index + 1) +
            ": " +
            moment(elm.date.seconds * 1000).format("h:mm:ss a, DD/MM/YYYY")
          );
        });

        const resTextButtons = resDatesArray.map((elm, index) => {
          return (
            <>
              <MDButton
                size="small"
                variant="outlined"
                color="error"
                key={index}
                circular={true}
                disabled={true}
              >
                {elm}
              </MDButton>{" "}
            </>
          );
        });

        return resTextButtons;
      } else return null;
    };

    return nudge?.response ? getResString(nudge) : null;
  };

  return showProcessing ? (
    <SubmissionProgress />
  ) : (
    <Card id="children-information-record">
      <MDBox m={3}>
        <MDBox mb={3}>
          {parentData ? (
            <MDTypography variant="h5">Children Information</MDTypography>
          ) : null}
        </MDBox>

        <MDBox mb={1}>
          {tabValue !== null && (
            <TabContext value={tabValue.toString()}>
              <MDBox sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleTabIndexChange}>
                  {!!parentData &&
                    parentData.data.childrenIds.map((childId, idx) => {
                      return (
                        <Tab
                          label={"Child - " + (idx + 1).toString()}
                          value={idx.toString()}
                          key={idx}
                        />
                      );
                    })}
                </TabList>
              </MDBox>
            </TabContext>
          )}
        </MDBox>

        <MDBox display="flex" justifyContent="flex-end">
          <MDBox p={1}>
            <ToggleButtonGroup
              color="success"
              value={toggleButtonValue}
              size="small"
              exclusive
              onChange={handleToggleButtonChange}
              aria-label="Platform"
              margin="2"
              sx={{
                p: 0,
                mb: 2,
                border: "1px solid lightgrey",
              }}
            >
              <ToggleButton value="nudges">
                <WhatsAppIcon />
                <MDBox mr={1}></MDBox> {"Nudges"}
                <MDBox mr={1}></MDBox>
              </ToggleButton>
              <ToggleButton value="formnudges">
                <SpeakerNotesIcon />
                <MDBox mr={1}></MDBox>
                {"Form Nudges"}
                <MDBox mr={1}></MDBox>
              </ToggleButton>
            </ToggleButtonGroup>
          </MDBox>
        </MDBox>

        <MDBox mb={3}>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {!!childData &&
              toggleButtonValue === "nudges" &&
              childData.data.nudges.map((nudge, idx) => (
                <TimelineItem key={"nudge-" + idx}>
                  <TimelineSeparator>
                    <TimelineDot variant="outlined" color="primary" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <>
                      {nudge.whatsappStatus === "To send" ? (
                        <MDBox
                          borderRadius="lg"
                          sx={{ p: 2, mb: 1, border: "1px solid red" }}
                        >
                          <Grid
                            container
                            rowSpacing={{ xs: 1, sm: 2 }}
                            columnSpacing={{ xs: 1, sm: 3 }}
                          >
                            <Grid item xs={12}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {nudge.id} - {nudge.type}
                              </MDTypography>
                            </Grid>
                            {!!dates && (
                              <Grid item xs={12}>
                                <MDTypography
                                  variant="body3"
                                  fontWeight="medium"
                                >
                                  Date:
                                </MDTypography>{" "}
                                <DatePicker
                                  name={`children.${idx}.dob`}
                                  placeholder="Select Date"
                                  format="yyyy-MM-dd"
                                  value={dates[idx].toDate()}
                                  onChange={async (dateISOstring) => {
                                    const date = new Date(dateISOstring);
                                    const seconds = parseInt(
                                      date.getTime() / 1000
                                    );
                                    const nanoseconds =
                                      (date.getTime() - seconds * 1000) *
                                      1000000;

                                    const newDates = _.cloneDeep(dates);
                                    newDates[idx].seconds = seconds;
                                    newDates[idx].nanoseconds = nanoseconds;

                                    const nudges = _.cloneDeep(
                                      childrenData.current[tabValue].data.nudges
                                    );
                                    nudges[idx].date.seconds = seconds;
                                    nudges[idx].date.nanoseconds = nanoseconds;

                                    for (
                                      let ii = idx + 1;
                                      ii < dates.length;
                                      ii++
                                    ) {
                                      const delta_seconds =
                                        dates[ii].seconds -
                                        dates[ii - 1].seconds;
                                      const newSeconds =
                                        newDates[ii - 1].seconds +
                                        delta_seconds;

                                      newDates[ii].seconds = newSeconds;
                                      nudges[ii].date.seconds = newSeconds;
                                    }

                                    setDates(newDates);
                                    childrenData.current[tabValue].data.nudges =
                                      nudges;

                                    await submitUpdateNudges(
                                      tabValue,
                                      childrenIds[tabValue],
                                      childrenData.current[tabValue].data.nudges
                                    );
                                  }}
                                />{" "}
                                <MDButton
                                  size="small"
                                  variant="gradient"
                                  color="info"
                                  startIcon={<WhatsAppIcon />}
                                  onClick={async () => {
                                    const month =
                                      childrenData.current[tabValue].data
                                        .nudges[idx].month;

                                    let response;
                                    if (month === "45") {
                                      // Send two whatsapp msg for nudge a45
                                      let templateName = "cradle2_a" + month;
                                      await sendNudgeTemplate(templateName);
                                      templateName = "cradle2_a" + month + "_2";
                                      ({ response } = await sendNudgeTemplate(
                                        templateName
                                      ));
                                    } else {
                                      const templateName = "cradle2_a" + month;
                                      ({ response } = await sendNudgeTemplate(
                                        templateName
                                      ));
                                    }

                                    const { seconds, nanoseconds } =
                                      getDateNowNanoseconds();
                                    // Update Dates on UI
                                    const newDates = _.cloneDeep(dates);
                                    newDates[idx].seconds = seconds;
                                    newDates[idx].nanoseconds = nanoseconds;
                                    setDates(newDates);

                                    // Update nudge.response field on Firebase
                                    const datenowNanosec = _.cloneDeep(
                                      childrenData.current[tabValue].data
                                        .nudges[idx].date
                                    );
                                    datenowNanosec.seconds = seconds;
                                    datenowNanosec.nanoseconds = nanoseconds;

                                    const newNudge = _.cloneDeep(nudge);
                                    if (newNudge?.response) {
                                      newNudge.response.push({
                                        date: datenowNanosec, //
                                        statusCode: response.data.statusCode,
                                        contents: response.data.responseJson,
                                      });
                                    } else {
                                      newNudge.response = [
                                        {
                                          date: datenowNanosec, //
                                          statusCode: response.data.statusCode,
                                          contents: response.data.responseJson,
                                        },
                                      ];
                                    }
                                    newNudge.whatsappStatus = "Sent";
                                    newNudge.date = datenowNanosec; //
                                    childrenData.current[tabValue].data.nudges[
                                      idx
                                    ] = newNudge;

                                    await submitUpdateNudges(
                                      tabValue,
                                      childrenIds[tabValue],
                                      childrenData.current[tabValue].data.nudges
                                    );
                                  }}
                                >
                                  Send Now
                                </MDButton>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <MDTypography variant="body3" fontWeight="medium">
                                Status:
                              </MDTypography>{" "}
                              <MDTypography
                                variant="body3"
                                fontWeight="regular"
                                color="error"
                              >
                                {nudge.whatsappStatus}
                              </MDTypography>
                            </Grid>

                            <Grid item xs={12}>
                              <MDTypography
                                variant="body3"
                                fontWeight="regular"
                              >
                                {nudge.contents}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                      ) : (
                        <MDBox
                          borderRadius="lg"
                          sx={{
                            p: 2,
                            mb: 1,
                            border: "1px solid grey",
                            backgroundColor: "lightgrey",
                          }}
                        >
                          <Grid
                            container
                            rowSpacing={{ xs: 1, sm: 2 }}
                            columnSpacing={{ xs: 1, sm: 3 }}
                          >
                            <Grid item xs={12}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {nudge.id} - {nudge.type}
                              </MDTypography>
                            </Grid>
                            {!!dates && (
                              <Grid item xs={12}>
                                <MDTypography
                                  variant="body3"
                                  fontWeight="medium"
                                >
                                  Date:
                                </MDTypography>{" "}
                                <DatePicker
                                  name={`children.${idx}.dob`}
                                  placeholder="Select Date"
                                  format="yyyy-MM-dd"
                                  value={dates[idx].toDate()}
                                  disabled={true}
                                />{" "}
                                <MDButton
                                  size="small"
                                  variant="gradient"
                                  color="info"
                                  startIcon={<WhatsAppIcon />}
                                  onClick={async () => {
                                    const month =
                                      childrenData.current[tabValue].data
                                        .nudges[idx].month;

                                    let response;
                                    if (month === "45") {
                                      // Send two whatsapp msg for nudge a45
                                      let templateName = "cradle2_a" + month;
                                      await sendNudgeTemplate(templateName);
                                      templateName = "cradle2_a" + month + "_2";
                                      ({ response } = await sendNudgeTemplate(
                                        templateName
                                      ));
                                    } else {
                                      const templateName = "cradle2_a" + month;
                                      ({ response } = await sendNudgeTemplate(
                                        templateName
                                      ));
                                    }

                                    const { seconds, nanoseconds } =
                                      getDateNowNanoseconds();
                                    // Update Dates on UI
                                    const newDates = _.cloneDeep(dates);
                                    newDates[idx].seconds = seconds;
                                    newDates[idx].nanoseconds = nanoseconds;
                                    setDates(newDates);

                                    // Update nudge.response field on Firebase
                                    const datenowNanosec = _.cloneDeep(
                                      childrenData.current[tabValue].data
                                        .nudges[idx].date
                                    );
                                    datenowNanosec.seconds = seconds;
                                    datenowNanosec.nanoseconds = nanoseconds;

                                    const newNudge = _.cloneDeep(nudge);
                                    if (newNudge?.response) {
                                      newNudge.response.push({
                                        date: datenowNanosec, //
                                        statusCode: response.data.statusCode,
                                        contents: response.data.responseJson,
                                      });
                                    } else {
                                      newNudge.response = [
                                        {
                                          date: datenowNanosec, //
                                          statusCode: response.data.statusCode,
                                          contents: response.data.responseJson,
                                        },
                                      ];
                                    }
                                    newNudge.whatsappStatus = "Sent";
                                    newNudge.date = datenowNanosec; //
                                    childrenData.current[tabValue].data.nudges[
                                      idx
                                    ] = newNudge;

                                    await submitUpdateNudges(
                                      tabValue,
                                      childrenIds[tabValue],
                                      childrenData.current[tabValue].data.nudges
                                    );
                                  }}
                                >
                                  Send Now
                                </MDButton>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <MDTypography variant="body3" fontWeight="medium">
                                Status:
                              </MDTypography>
                              <MDTypography
                                variant="body3"
                                fontWeight="regular"
                                color="info"
                              >
                                {nudge.whatsappStatus} @{" "}
                                {getResponseString(nudge)}
                              </MDTypography>
                            </Grid>

                            <Grid item xs={12}>
                              <MDTypography
                                variant="body3"
                                fontWeight="regular"
                              >
                                {nudge.contents}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                      )}
                    </>
                  </TimelineContent>
                </TimelineItem>
              ))}
            {!!childData &&
              toggleButtonValue === "formnudges" &&
              childData.data.formnudges.map((formnudge, idx) => (
                <TimelineItem key={"formnudge-" + idx}>
                  <TimelineSeparator>
                    <TimelineDot variant="outlined" color="primary" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <>
                      {formnudge.whatsappStatus === "Sent" ? (
                        <MDBox
                          borderRadius="lg"
                          sx={{
                            p: 2,
                            mb: 1,
                            border: "1px solid grey",
                            backgroundColor: "lightgrey",
                          }}
                        >
                          <Grid
                            container
                            rowSpacing={{ xs: 1, sm: 2 }}
                            columnSpacing={{ xs: 1, sm: 3 }}
                          >
                            <Grid item xs={12}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {formnudge.id} - {formnudge.type}
                              </MDTypography>
                            </Grid>
                            {!!dates && (
                              <Grid item xs={12}>
                                <MDTypography
                                  variant="body3"
                                  fontWeight="medium"
                                >
                                  Date:
                                </MDTypography>{" "}
                                <DatePicker
                                  name={`children.${idx}.dob`}
                                  placeholder="Select Date"
                                  format="yyyy-MM-dd"
                                  value={dates[idx].toDate()}
                                  disabled={true}
                                />{" "}
                                <MDButton
                                  size="small"
                                  variant="gradient"
                                  color="info"
                                  startIcon={<WhatsAppIcon />}
                                  onClick={async () => {
                                    const fid =
                                      childrenData.current[tabValue].data
                                        .formnudges[idx].id;

                                    const templateName = "cradle2_form" + fid;

                                    const { response } =
                                      await sendNudgeTemplate(templateName);

                                    const { seconds, nanoseconds } =
                                      getDateNowNanoseconds();
                                    // Update Dates on UI
                                    const newDates = _.cloneDeep(dates);
                                    newDates[idx].seconds = seconds;
                                    newDates[idx].nanoseconds = nanoseconds;
                                    setDates(newDates);

                                    // Update formnudge.response field on Firebase
                                    const datenowNanosec = _.cloneDeep(
                                      childrenData.current[tabValue].data
                                        .formnudges[idx].date
                                    );
                                    datenowNanosec.seconds = seconds;
                                    datenowNanosec.nanoseconds = nanoseconds;

                                    const newFormNudge = _.cloneDeep(formnudge);
                                    if (newFormNudge?.response) {
                                      newFormNudge.response.push({
                                        date: datenowNanosec, //
                                        statusCode: response.data.statusCode,
                                        contents: response.data.responseJson,
                                      });
                                    } else {
                                      newFormNudge.response = [
                                        {
                                          date: datenowNanosec, //
                                          statusCode: response.data.statusCode,
                                          contents: response.data.responseJson,
                                        },
                                      ];
                                    }
                                    newFormNudge.whatsappStatus = "Sent";
                                    newFormNudge.date = datenowNanosec; //
                                    childrenData.current[
                                      tabValue
                                    ].data.formnudges[idx] = newFormNudge;

                                    await submitUpdateFormNudges(
                                      tabValue,
                                      childrenIds[tabValue],
                                      childrenData.current[tabValue].data
                                        .formnudges
                                    );
                                  }}
                                >
                                  Send Now
                                </MDButton>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <MDTypography variant="body3" fontWeight="medium">
                                Status:
                              </MDTypography>{" "}
                              <MDTypography
                                variant="body3"
                                fontWeight="regular"
                                color="info"
                              >
                                {formnudge.whatsappStatus} @{" "}
                                {getResponseString(formnudge)}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12}>
                              <MDTypography
                                variant="body3"
                                fontWeight="regular"
                              >
                                {formnudge.contents}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                      ) : (
                        <MDBox
                          borderRadius="lg"
                          sx={{ p: 2, mb: 1, border: "1px solid red" }}
                        >
                          <Grid
                            container
                            rowSpacing={{ xs: 1, sm: 2 }}
                            columnSpacing={{ xs: 1, sm: 3 }}
                          >
                            <Grid item xs={12}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {formnudge.id} - {formnudge.type}
                              </MDTypography>
                            </Grid>
                            {!!dates && (
                              <Grid item xs={12}>
                                <MDTypography
                                  variant="body3"
                                  fontWeight="medium"
                                >
                                  Date:
                                </MDTypography>{" "}
                                <DatePicker
                                  name={`children.${idx}.dob`}
                                  placeholder="Select Date"
                                  format="yyyy-MM-dd"
                                  value={dates[idx].toDate()}
                                  onChange={async (dateISOstring) => {
                                    const date = new Date(dateISOstring);
                                    const seconds = parseInt(
                                      date.getTime() / 1000
                                    );
                                    const nanoseconds =
                                      (date.getTime() - seconds * 1000) *
                                      1000000;
                                    const newDates = _.cloneDeep(dates);
                                    newDates[idx].seconds = seconds;
                                    newDates[idx].nanoseconds = nanoseconds;

                                    const formnudges = _.cloneDeep(
                                      childrenData.current[tabValue].data
                                        .formnudges
                                    );
                                    formnudges[idx].date.seconds = seconds;
                                    formnudges[idx].date.nanoseconds =
                                      nanoseconds;

                                    for (
                                      let ii = idx + 1;
                                      ii < dates.length;
                                      ii++
                                    ) {
                                      const delta_seconds =
                                        dates[ii].seconds -
                                        dates[ii - 1].seconds;
                                      const newSeconds =
                                        newDates[ii - 1].seconds +
                                        delta_seconds;

                                      newDates[ii].seconds = newSeconds;
                                      formnudges[ii].date.seconds = newSeconds;
                                    }

                                    setDates(newDates);
                                    childrenData.current[
                                      tabValue
                                    ].data.formnudges = formnudges;

                                    await submitUpdateFormNudges(
                                      tabValue,
                                      childrenIds[tabValue],
                                      childrenData.current[tabValue].data
                                        .formnudges
                                    );
                                  }}
                                />{" "}
                                <MDButton
                                  size="small"
                                  variant="gradient"
                                  color="info"
                                  startIcon={<WhatsAppIcon />}
                                  onClick={async () => {
                                    const fid =
                                      childrenData.current[tabValue].data
                                        .formnudges[idx].id;

                                    const templateName = "cradle2_form" + fid;

                                    const { response } =
                                      await sendNudgeTemplate(templateName);

                                    const { seconds, nanoseconds } =
                                      getDateNowNanoseconds();
                                    // Update Dates on UI
                                    const newDates = _.cloneDeep(dates);
                                    newDates[idx].seconds = seconds;
                                    newDates[idx].nanoseconds = nanoseconds;
                                    setDates(newDates);

                                    // Update formnudge.response field on Firebase
                                    const datenowNanosec = _.cloneDeep(
                                      childrenData.current[tabValue].data
                                        .formnudges[idx].date
                                    );
                                    datenowNanosec.seconds = seconds;
                                    datenowNanosec.nanoseconds = nanoseconds;

                                    const newFormNudge = _.cloneDeep(formnudge);
                                    if (newFormNudge?.response) {
                                      newFormNudge.response.push({
                                        date: datenowNanosec, //
                                        statusCode: response.data.statusCode,
                                        contents: response.data.responseJson,
                                      });
                                    } else {
                                      newFormNudge.response = [
                                        {
                                          date: datenowNanosec, //
                                          statusCode: response.data.statusCode,
                                          contents: response.data.responseJson,
                                        },
                                      ];
                                    }
                                    newFormNudge.whatsappStatus = "Sent";
                                    newFormNudge.date = datenowNanosec; //
                                    childrenData.current[
                                      tabValue
                                    ].data.formnudges[idx] = newFormNudge;

                                    await submitUpdateFormNudges(
                                      tabValue,
                                      childrenIds[tabValue],
                                      childrenData.current[tabValue].data
                                        .formnudges
                                    );
                                  }}
                                >
                                  Send Now
                                </MDButton>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <MDTypography variant="body3" fontWeight="medium">
                                Status:
                              </MDTypography>{" "}
                              <MDTypography
                                variant="body3"
                                fontWeight="regular"
                                color="error"
                              >
                                {formnudge.whatsappStatus}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12}>
                              <MDTypography
                                variant="body3"
                                fontWeight="regular"
                              >
                                {formnudge.contents}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                      )}
                    </>
                  </TimelineContent>
                </TimelineItem>
              ))}
          </Timeline>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ChildrenInformation;
