import * as Yup from "yup";
import form, { childForm } from "pages/participants/new/schemas/form";

const {
  formField: { userName, email, mobile, participantGroup, dob },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [userName.name]: Yup.string()
    .required(userName.errorMsg)
    .max(200, userName.invalidMsg),
  [email.name]: Yup.string().nullable().notRequired(),
  [mobile.name]: Yup.string()
    .required(mobile.errorMsg)
    .max(10, mobile.invalidMsg),
  [participantGroup.name]: Yup.array(
    Yup.string().required(participantGroup.errorMsg)
  )
    .min(1, participantGroup.min1Msg)
    .max(10, participantGroup.max10Msg),
  [dob.name]: Yup.array(Yup.string().required(dob.errorMsg))
    .min(1, dob.min1Msg)
    .max(10, dob.max10Msg),
});

const {
  formField: { childGroup, childDob },
} = childForm;

const childValidations = Yup.object().shape({
  [childGroup.name]: Yup.string()
    .required(childGroup.errorMsg)
    .max(200, childGroup.invalidMsg),
  [childDob.name]: Yup.string()
    .required(childDob.errorMsg)
    .max(200, childDob.invalidMsg),
});

export { validations as default, noValidation, childValidations };
