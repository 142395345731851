import form, {
  rolesForm,
  signatureAttachmentsForm,
} from "pages/settings/users/manage/schemas/form";

const {
  formField: { displayName, designation, email, roles },
} = form;

const initialValues = {
  [displayName.name]: "",
  [designation.name]: "",
  [email.name]: "",
  [roles.name]: [],
};

const {
  formField: { roleName },
} = rolesForm;

const rolesInitialValues = {
  [roleName.name]: "",
};

const {
  formField: { signatureAttachmentFile },
} = signatureAttachmentsForm;

const signatureAttachmentsInitialValues = {
  [signatureAttachmentFile.name]: "",
};

export {
  initialValues as default,
  rolesInitialValues,
  signatureAttachmentsInitialValues,
};
