import { useState, useEffect } from "react";
import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import Grid from "@mui/material/Grid";
//import Card from "@mui/material/Card";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

//import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
//import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

//import SubmissionProgress from "components/organisms/SubmissionProgress";
//import PermissionDenied from "components/organisms/PermissionDenied";
//import InvalidOperation from "components/organisms/InvalidOperation";
//import IncompleteOperation from "components/organisms/IncompleteOperation";

//import ModeEditIcon from "@mui/icons-material/ModeEdit";
//import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
//import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import { Table, Popover, Whisper } from "rsuite";

//import { Formik, Form } from "formik";
//import form from "pages/nudges/groupA/schemas/form";
//import NudgeDetails from "pages/nudges/groupA/manage/components/NudgeDetails";

//import Dialog from "@mui/material/Dialog";
//import DialogActions from "@mui/material/DialogActions";
//import DialogContent from "@mui/material/DialogContent";
//import DialogTitle from "@mui/material/DialogTitle";

//import { AllowedTo } from "react-abac";
import { useCalculatorManager } from "pages/calculator/hooks/useCalculatorManager";
//import initialValues from "pages/nudges/groupA/schemas/initialValues";
//import { List } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

function Calculator36months() {
  const { modeTitle, response } = useCalculatorManager();
  const responseMonth36 = response.data;
  //console.log(JSON.stringify(responseMonth36));

  const defaultColumns = [
    {
      key: "cradle2Id",
      label: "Id",
      flexGrow: 1,
    },
    {
      key: "asq3_communicationSum",
      label: "Sum_C",
      flexGrow: 1,
    },
    {
      key: "asq3_grossmotorSum",
      label: "Sum_G",
      flexGrow: 1,
    },
    {
      key: "asq3_finemotorSum",
      label: "Sum_F",
      flexGrow: 1,
    },
    {
      key: "asq3_problemsolvingSum",
      label: "Sum_P",
      flexGrow: 1,
    },
    {
      key: "asq3_personalsocialSum",
      label: "Sum_PS",
      flexGrow: 1,
    },
    {
      key: "asqse2_total",
      label: "Sum_SE",
      flexGrow: 1,
    },
    {
      key: "sd_emotionalproblems",
      label: "SD_EP",
      flexGrow: 1,
    },
    {
      key: "sd_conductproblems",
      label: "SD_CP",
      flexGrow: 1,
    },
    {
      key: "sd_hyperactivity",
      label: "SD_H",
      flexGrow: 1,
    },
    {
      key: "sd_peerproblems",
      label: "SD_PP",
      flexGrow: 1,
    },
    {
      key: "sd_prosocial",
      label: "SD_PS",
      flexGrow: 1,
    },
    {
      key: "action",
      label: "ACTION",
      flexGrow: 1,
    },
  ];

  const [data, setData] = useState([]);

  //const [columnKeys, setColumnKeys] = useState(
  //  defaultColumns.map((column) => column.key)
  //);
  //console.log(JSON.stringify(columnKeys));

  const getCutoffLabel = (cutoffLabel) => {
    return cutoffLabel === "BLACK" ? (
      <MDBox
        p={0.8}
        sx={{
          borderRadius: "8px",
          backgroundColor: "whitesmoke",
        }}
      >
        <MDTypography variant="body3" color="black">
          BLACK
        </MDTypography>
      </MDBox>
    ) : cutoffLabel === "WHITE" ? (
      <MDBox
        p={0.8}
        sx={{
          borderRadius: "8px",
          backgroundColor: "darkgrey",
        }}
      >
        <MDTypography variant="body3" color="white">
          WHITE
        </MDTypography>
      </MDBox>
    ) : (
      <MDBox
        p={0.8}
        sx={{
          borderRadius: "8px",
          backgroundColor: "lightgrey",
        }}
      >
        <MDTypography variant="body3" color="dark">
          GREY
        </MDTypography>
      </MDBox>
    );
  };

  const getCutoff = (score, CutoffLB, LabelLB, CutoffUB, LabelUB) => {
    return parseFloat(score) <= CutoffLB
      ? getCutoffLabel(LabelLB)
      : parseFloat(score) >= CutoffUB
      ? getCutoffLabel(LabelUB)
      : getCutoffLabel("GREY");
  };

  const Asq3CommunicationCell = ({ rowData, dataKey, ...props }) => {
    const CutoffLB_ASQ3Communication = 30;
    const CutoffUB_ASQ3Communication = 45;
    const title = rowData.cradle2Id + ": ASQ3-Communication";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Comm 1</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication01}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication01_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Comm 2a</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication02a}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication02a_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Comm 3</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication03}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication03_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Comm 4</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication04}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication04_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Comm 5</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication05}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication05_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Comm 6</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication06}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication06_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Sum_C</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            {getCutoff(
              rowData.asq3_communicationSum,
              CutoffLB_ASQ3Communication,
              "BLACK",
              CutoffUB_ASQ3Communication,
              "WHITE"
            )}
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communicationSum}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const Asq3GrossMotorCell = ({ rowData, dataKey, ...props }) => {
    const CutoffLB_ASQ3GrossMotor = 35;
    const CutoffUB_ASQ3GrossMotor = 50;
    const title = rowData.cradle2Id + ": ASQ3-Gross Motor";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Gross Motor 1</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor01}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor01_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Gross Motor 2</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor02}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor02_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Gross Motor 3</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor03}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor03_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Gross Motor 4</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor04}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor04_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Gross Motor 5</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor05}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor05_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Gross Motor 6</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor06}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor06_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Sum_G</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            {getCutoff(
              rowData.asq3_grossmotorSum,
              CutoffLB_ASQ3GrossMotor,
              "BLACK",
              CutoffUB_ASQ3GrossMotor,
              "WHITE"
            )}
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotorSum}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const Asq3FineMotorCell = ({ rowData, dataKey, ...props }) => {
    const CutoffLB_ASQ3FineMotor = 15;
    const CutoffUB_ASQ3FineMotor = 35;
    const title = rowData.cradle2Id + ": ASQ3-Fine Motor";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Fine Motor 1</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor01}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor01_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Fine Motor 2</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor02}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor02_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Fine Motor 3</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor03}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor03_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Fine Motor 4</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor04}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor04_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Fine Motor 5</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor05}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor05_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Fine Motor 6</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor06}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor06_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Sum_F</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            {getCutoff(
              rowData.asq3_finemotorSum,
              CutoffLB_ASQ3FineMotor,
              "BLACK",
              CutoffUB_ASQ3FineMotor,
              "WHITE"
            )}
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotorSum}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const Asq3ProblemSolvingCell = ({ rowData, dataKey, ...props }) => {
    const CutoffLB_ASQ3ProblemSolving = 15;
    const CutoffUB_ASQ3ProblemSolving = 35;
    const title = rowData.cradle2Id + ": ASQ3-Problem Solving";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Prob. Solving 1</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving01}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving01_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Prob. Solving 2</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving02}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving02_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Prob. Solving 3a</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving03a}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving03a_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Prob. Solving 4</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving04}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving04_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Prob. Solving 5</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving05}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving05_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Prob. Solving 6</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving06}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving06_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Sum_P</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            {getCutoff(
              rowData.asq3_problemsolvingSum,
              CutoffLB_ASQ3ProblemSolving,
              "BLACK",
              CutoffUB_ASQ3ProblemSolving,
              "WHITE"
            )}
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotorSum}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const Asq3PersonalSocialCell = ({ rowData, dataKey, ...props }) => {
    const CutoffLB_ASQ3PersonalSocial = 35;
    const CutoffUB_ASQ3PersonalSocial = 45;
    const title = rowData.cradle2Id + ": ASQ3-Personal Social";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Personal Social 1</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial01}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial01_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Personal Social 2</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial02}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial02_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Personal Social 3</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial03}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial03_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Personal Social 4</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial04}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial04_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Personal Social 5</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial05}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial05_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Personal Social 6</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial06}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial06_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Sum_P</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            {getCutoff(
              rowData.asq3_personalsocialSum,
              CutoffLB_ASQ3PersonalSocial,
              "BLACK",
              CutoffUB_ASQ3PersonalSocial,
              "WHITE"
            )}
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocialSum}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const Asqse2CellElm = (rowData, label, dataKey1, dataKey2) => {
    return (
      <>
        <Grid item xs={2}>
          <MDTypography variant="body3">{label}</MDTypography>
        </Grid>
        <Grid item xs={2} display={"flex"} justifyContent={"center"}>
          <MDTypography variant="body3">{rowData[dataKey1]}</MDTypography>
        </Grid>
        <Grid item xs={2} display={"flex"} justifyContent={"center"}>
          <MDTypography variant="body3">{rowData[dataKey2]}</MDTypography>
        </Grid>
      </>
    );
  };

  const Asqse2Cell = ({ rowData, dataKey, ...props }) => {
    const CutoffLB_ASQ3_SE2 = 70;
    const CutoffUB_ASQ3_SE2 = 105;
    const title = rowData.cradle2Id + ": ASQ3-SE2";
    const speaker = (
      <Popover title={title} style={{ width: 800 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          {Asqse2CellElm(rowData, "ASQ-SE2 1", "asqse2_01", "asqse2_01_score")}
          {Asqse2CellElm(
            rowData,
            "ASQ-SE2 21a",
            "asqse2_21a",
            "asqse2_21a_score"
          )}
          {Asqse2CellElm(rowData, "ASQ-SE2 2", "asqse2_02", "asqse2_02_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 22", "asqse2_22", "asqse2_22_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 3", "asqse2_03", "asqse2_03_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 23", "asqse2_23", "asqse2_23_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 4", "asqse2_04", "asqse2_04_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 24", "asqse2_24", "asqse2_24_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 5", "asqse2_05", "asqse2_05_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 25", "asqse2_25", "asqse2_25_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 6", "asqse2_06", "asqse2_06_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 26", "asqse2_26", "asqse2_26_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 7", "asqse2_07", "asqse2_07_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 27", "asqse2_27", "asqse2_27_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 8", "asqse2_08", "asqse2_08_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 28", "asqse2_28", "asqse2_28_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 9", "asqse2_09", "asqse2_09_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 29", "asqse2_29", "asqse2_29_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 10", "asqse2_10", "asqse2_10_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 30", "asqse2_30", "asqse2_30_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 11", "asqse2_11", "asqse2_11_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 31", "asqse2_31", "asqse2_31_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 12", "asqse2_12", "asqse2_12_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 32", "asqse2_32", "asqse2_32_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 13", "asqse2_13", "asqse2_13_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 33", "asqse2_33", "asqse2_33_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 14", "asqse2_14", "asqse2_14_score")}
          {Asqse2CellElm(
            rowData,
            "ASQ-SE2 34a",
            "asqse2_34a",
            "asqse2_34a_score"
          )}
          {Asqse2CellElm(
            rowData,
            "ASQ-SE2 15a",
            "asqse2_15a",
            "asqse2_15a_score"
          )}
          {Asqse2CellElm(
            rowData,
            "ASQ-SE2 35a",
            "asqse2_35a",
            "asqse2_35a_score"
          )}
          {Asqse2CellElm(rowData, "ASQ-SE2 16", "asqse2_16", "asqse2_16_score")}
          <Grid item xs={6}></Grid>
          {Asqse2CellElm(rowData, "ASQ-SE2 17", "asqse2_17", "asqse2_17_score")}
          <Grid item xs={6}></Grid>
          {Asqse2CellElm(rowData, "ASQ-SE2 18", "asqse2_18", "asqse2_18_score")}
          <Grid item xs={6}></Grid>
          {Asqse2CellElm(rowData, "ASQ-SE2 19", "asqse2_19", "asqse2_19_score")}
          <Grid item xs={6}></Grid>
          {Asqse2CellElm(rowData, "ASQ-SE2 20", "asqse2_20", "asqse2_20_score")}
          <Grid item xs={2}>
            <MDTypography variant="body3">Sum_SE</MDTypography>
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {getCutoff(
                rowData.asqse2_total,
                CutoffLB_ASQ3_SE2,
                "WHITE",
                CutoffUB_ASQ3_SE2,
                "BLACK"
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">{rowData.asqse2_total}</MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const getCutoffSDLabel = (cutoffLabel) => {
    return (
      <MDBox
        p={0.8}
        sx={{
          borderRadius: "8px",
          backgroundColor: "whitesmoke",
        }}
      >
        <MDTypography variant="body3" color="black">
          {cutoffLabel}
        </MDTypography>
      </MDBox>
    );
  };

  const getCutoffSD = (score, CutoffB1, CutoffB2, CutoffB3, ResB1) => {
    if (ResB1 === "Very High") {
      return parseFloat(score) >= CutoffB1
        ? getCutoffSDLabel("Very High")
        : parseFloat(score) >= CutoffB2
        ? getCutoffSDLabel("High")
        : parseFloat(score) >= CutoffB3
        ? getCutoffSDLabel("Slightly Raised")
        : getCutoffSDLabel("Close to Average");
    } else {
      return parseFloat(score) <= CutoffB1
        ? getCutoffSDLabel("Very Low")
        : parseFloat(score) <= CutoffB2
        ? getCutoffSDLabel("Low")
        : parseFloat(score) <= CutoffB3
        ? getCutoffSDLabel("Slightly Lowered")
        : getCutoffSDLabel("Close to Average");
    }
  };

  const SdEmotionalProblemsCell = ({ rowData, dataKey, ...props }) => {
    const CutoffB1 = 5;
    const CutoffB2 = 4;
    const CutoffB3 = 3;
    const ResB1 = "Very High";
    const title = rowData.cradle2Id + ": SD-Emotional Problems";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 3</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties03}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties03_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 8</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties08}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties08_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 13</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties13}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties13_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 16</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties16}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties16_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 24</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties24}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties24_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">EP Score</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {getCutoffSD(
                rowData.sd_emotionalproblems,
                CutoffB1,
                CutoffB2,
                CutoffB3,
                ResB1
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.sd_emotionalproblems}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const SdConductProblemsCell = ({ rowData, dataKey, ...props }) => {
    const CutoffB1 = 6;
    const CutoffB2 = 5;
    const CutoffB3 = 4;
    const ResB1 = "Very High";
    const title = rowData.cradle2Id + ": SD-Conduct Problems";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 5</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties05}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties05_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 7</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties07}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties07_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 12</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties12}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties12_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 18</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties18}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties18_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 22</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties22}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties22_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">CP Score</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {getCutoffSD(
                rowData.sd_conductproblems,
                CutoffB1,
                CutoffB2,
                CutoffB3,
                ResB1
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.sd_conductproblems}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const SdHyperactivityCell = ({ rowData, dataKey, ...props }) => {
    const CutoffB1 = 8;
    const CutoffB2 = 7;
    const CutoffB3 = 6;
    const ResB1 = "Very High";
    const title = rowData.cradle2Id + ": SD-Hyperactivity";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 2</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties02}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties02_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 10</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties10}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties10_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 15</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties15}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties15_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 21</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties21}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties21_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 25</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties25}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties25_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">HP Score</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {getCutoffSD(
                rowData.sd_hyperactivity,
                CutoffB1,
                CutoffB2,
                CutoffB3,
                ResB1
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.sd_hyperactivity}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const SdPeerProblemsCell = ({ rowData, dataKey, ...props }) => {
    const CutoffB1 = 5;
    const CutoffB2 = 4;
    const CutoffB3 = 3;
    const ResB1 = "Very High";
    const title = rowData.cradle2Id + ": SD-Peer Problems";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 6</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties06}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties06_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 11</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties11}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties11_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 14</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties14}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties14_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 19</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties19}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties19_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 23</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties23}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties23_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">PP Score</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {getCutoffSD(
                rowData.sd_peerproblems,
                CutoffB1,
                CutoffB2,
                CutoffB3,
                ResB1
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.sd_peerproblems}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const SdProsocialCell = ({ rowData, dataKey, ...props }) => {
    const CutoffB1 = 4;
    const CutoffB2 = 5;
    const CutoffB3 = 6;
    const ResB1 = "Very Low";
    const title = rowData.cradle2Id + ": SD-Prosocial";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 1</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties01}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties01_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 4</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties04}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties04_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 9</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties09}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties09_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 17</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties17}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties17_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 20</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties20}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties20_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">PS Score</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {getCutoffSD(
                rowData.sd_prosocial,
                CutoffB1,
                CutoffB2,
                CutoffB3,
                ResB1
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">{rowData.sd_prosocial}</MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  useEffect(() => {
    if (!!responseMonth36) {
      const rows = responseMonth36.map((row) => {
        return row.data;
      });

      setData(rows);
    }
  }, [responseMonth36]);

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      {data.length > 0 && (
        <MDBox mt={3}>
          <Table
            height={1000}
            data={data}
            //hover={true}
            showHeader={true}
            bordered={true}
            cellBordered={true}
            //onRowClick={rowData => {
            //  console.log(rowData);
            //}}
          >
            {defaultColumns.map((column) => {
              const { key, label, ...rest } = column;

              return key === "asq3_communicationSum" ? (
                <Column {...rest} key={key}>
                  <HeaderCell>{label}</HeaderCell>
                  <Asq3CommunicationCell dataKey="asq3_communicationSum" />
                </Column>
              ) : key === "asq3_grossmotorSum" ? (
                <Column {...rest} key={key}>
                  <HeaderCell>{label}</HeaderCell>
                  <Asq3GrossMotorCell dataKey="asq3_grossmotorSum" />
                </Column>
              ) : key === "asq3_finemotorSum" ? (
                <Column {...rest} key={key}>
                  <HeaderCell>{label}</HeaderCell>
                  <Asq3FineMotorCell dataKey="asq3_finemotorSum" />
                </Column>
              ) : key === "asq3_problemsolvingSum" ? (
                <Column {...rest} key={key}>
                  <HeaderCell>{label}</HeaderCell>
                  <Asq3ProblemSolvingCell dataKey="asq3_problemsolvingSum" />
                </Column>
              ) : key === "asq3_personalsocialSum" ? (
                <Column {...rest} key={key}>
                  <HeaderCell>{label}</HeaderCell>
                  <Asq3PersonalSocialCell dataKey="asq3_personalsocialSum" />
                </Column>
              ) : key === "asqse2_total" ? (
                <Column {...rest} key={key}>
                  <HeaderCell>{label}</HeaderCell>
                  <Asqse2Cell dataKey="asqse2_total" />
                </Column>
              ) : key === "sd_emotionalproblems" ? (
                <Column {...rest} key={key}>
                  <HeaderCell>{label}</HeaderCell>
                  <SdEmotionalProblemsCell dataKey="sd_emotionalproblems" />
                </Column>
              ) : key === "sd_conductproblems" ? (
                <Column {...rest} key={key}>
                  <HeaderCell>{label}</HeaderCell>
                  <SdConductProblemsCell dataKey="sd_conductproblems" />
                </Column>
              ) : key === "sd_hyperactivity" ? (
                <Column {...rest} key={key}>
                  <HeaderCell>{label}</HeaderCell>
                  <SdHyperactivityCell dataKey="sd_hyperactivity" />
                </Column>
              ) : key === "sd_peerproblems" ? (
                <Column {...rest} key={key}>
                  <HeaderCell>{label}</HeaderCell>
                  <SdPeerProblemsCell dataKey="sd_peerproblems" />
                </Column>
              ) : key === "sd_prosocial" ? (
                <Column {...rest} key={key}>
                  <HeaderCell>{label}</HeaderCell>
                  <SdProsocialCell dataKey="sd_prosocial" />
                </Column>
              ) : key === "action" ? (
                <Column {...rest} key={key}>
                  <HeaderCell>{label}</HeaderCell>
                  <Cell fixed="right">
                    {(rowData) => {
                      return (
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="left"
                        >
                          <MDButton
                            size="small"
                            variant="gradient"
                            color="success"
                            iconOnly
                            onClick={() => {
                              console.log("icon pressed");
                            }}
                          >
                            <WhatsAppIcon />
                          </MDButton>
                        </MDBox>
                      );
                    }}
                  </Cell>
                </Column>
              ) : (
                <Column {...rest} key={key}>
                  <HeaderCell>{label}</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return (
                        <MDTypography variant="body2">
                          {rowData[key]}
                        </MDTypography>
                      );
                    }}
                  </Cell>
                </Column>
              );

              /*if (key === "asq3_communicationSum") {
                return (
                  <Column {...rest} key={key}>
                    <HeaderCell>{label}</HeaderCell>
                    <Asq3CommunicationCell dataKey="asq3_communicationSum" />
                  </Column>
                );
              } else
                return (
                  <Column {...rest} key={key}>
                    <HeaderCell>{label}</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        return (
                          <MDTypography variant="body2">
                            {rowData[key]}
                          </MDTypography>
                        );
                      }}
                    </Cell>
                  </Column>
                );*/
            })}
          </Table>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Calculator36months;
